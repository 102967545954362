import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import imgLogin from '../../img/cronoslifeconfondo.png'
import '../../css/login/loginUser.css'
import CryptoJS from 'crypto-js';
import ConexionAPI from "../../conexion/conexion.json"

export const LoginUser = () => {
    window.sessionStorage.clear()

    const [errorMessageDNI, setErrorMessageDNI] = React.useState("");
    const [errorMessageClave, setErrorMessageClave] = React.useState("");

    const ValidarDatosFormulario = e => {
        
        e.preventDefault();
        let valDNI= e.target.DNI.value;
        let valClave= e.target.clave.value;

        if(validateDni(valDNI) && ValidarClave(valClave)){
            getUsuarios(valDNI,valClave)
        }
    }

    const getUsuarios=async(valDNI,valClave)=>{
                
        const secretPass = 'secretPass';
        const passEncrypt = CryptoJS.AES.encrypt(valClave, secretPass).toString();

        const data = { 
            DNIRUC: valDNI,
            PASS: passEncrypt
          };

          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          };

          //fetch('http://localhost:4000/api/getUser', options)
          fetch(ConexionAPI.conexion+'/api/getUser', options)
            .then(response => response.json())
            .then(data => { 
                            if(data.length === 1){
                                console.log(data[0].id)
                                const secretPass = 'secretPass';
                                const nameUser = data[0].nombres + ', '+ data[0].apellido_paterno;
                                const idUser = (data[0].id).toString();
                                const documentUser = (data[0].DNIRUC).toString();
                                const rolUser = (data[0].rol).toString();
                                const nameUserEncrypt = CryptoJS.AES.encrypt(nameUser, secretPass).toString();
                                const idUserEncrypt = CryptoJS.AES.encrypt(idUser, secretPass).toString();
                                const documentUserEncrypt = CryptoJS.AES.encrypt(documentUser, secretPass).toString();
                                const rolUserEncrypt = CryptoJS.AES.encrypt(rolUser, secretPass).toString();
                                window.sessionStorage.setItem('tkn',nameUserEncrypt);
                                window.sessionStorage.setItem('utkn',idUserEncrypt);
                                window.sessionStorage.setItem('dtkn',documentUserEncrypt);
                                window.sessionStorage.setItem('rtkn',rolUserEncrypt);
                                window.open("/home","_self")
                            }else{
                                setErrorMessageClave("Error al colocar las credenciales.")
                            }
                          })
            .catch(error => console.error(error));
      
    }

    const validateDni = (valDNI) => {
        let rpta = true;

        if(valDNI){

            if(valDNI.length < 8){
                setErrorMessageDNI("Debe ingresar un documento valido");
                rpta = false;
            }else{
                setErrorMessageDNI();
            }
        }else{
            setErrorMessageDNI("Debe ingresar un documento valido");
            rpta = false;
        }
        return rpta;
    }

    const ValidarClave=(valorClave)=>{
        var rpta=1;
        setErrorMessageClave();

        if(valorClave==''){
            setErrorMessageClave("Debe de ingresar un valor válido");
            rpta=0;
        }

        return rpta;
    }

    const validateTypeNumber = (e)=>{
        const keyCode = e.which || e.keyCode;
        const tecla = String.fromCharCode(keyCode);
        if (!/^\d+$/.test(tecla)) {
          e.preventDefault();
          console.log(keyCode)
        }

    }

  return (
        <div className="">
            <div id="containForm" className="row d-flex justify-content-center align-items-center marginOff vh-100">
                <div className="col-lg-6 logoLogin d-flex justify-content-center align-items-center">
                    <div>
                        <img className='img-fluid' src={ imgLogin }/>
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                    <div className="formLogin w-75">
                        <form className="d-flex flex-column align-items-start m-3 gap-3" onSubmit={ValidarDatosFormulario}>
                            <h1 className="text-center">¡Bienvenido!</h1>
                            <b>Usuario</b>
                            <input type="text" name='DNI' autoComplete="off" maxLength="11" className="form-control" placeholder="Ingresar documento"/>
                            {errorMessageDNI && <div className="errorMessage"> {errorMessageDNI} </div>}
                            <b>Clave</b>
                            <input type="password" name='clave' onChange={e => ValidarClave(e.target.value)} className="form-control" placeholder="Ingresar su clave"/>
                            {errorMessageClave && <div className="errorMessage"> {errorMessageClave} </div>}
                            <button type='submit' value='Enviar' className="btn btn-warning btnLogin w-100">INGRESAR</button>
                        </form>
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center">
                    <div className="footerLogin w-75">
                        dasdsad  ads asd sad asd as sa as
                    </div>
                </div>
            </div>
        </div>
    )
}
