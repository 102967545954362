import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Navbar } from "../ui/navbar";
import { Table, Pagination} from 'react-bootstrap'
import imgDelete from '../../img/trash.svg';
import CryptoJS from 'crypto-js';
import ConexionAPI from "../../conexion/conexion.json"

export const ListSale = () => {
    const [tableData, setTableData] = React.useState(''); // tus datos de la tabla
    const [currentPage, setCurrentPage] = React.useState(1);
    const rowsPerPage = 30; // el número de filas por página
    const [dataForsale, setDataForSale] = React.useState()

    function render(){
        if(!tableData){
            getSaleAPI()
        }
    }

    const formatearNumeroASoles = (numero) => {
        const formatter = new Intl.NumberFormat('es-PE', {
                    style: 'currency',
                    currency: 'PEN',
                    });
        return formatter.format(numero);
    };

    const getSaleAPI = async()=>{
        const   options =   {
                                method: 'POST',
                                headers: {
                                            'Content-Type': 'application/json'
                                            }
                            };

            
        try {
            //const response = await fetch('http://localhost:4000/api/getSale',options);
            const response = await fetch(ConexionAPI.conexion+'/api/getSale',options);
            const data = await response.json();
            return setTableData(data)
        } catch (error) {
            console.error(error);
        }                    
    }

    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
    const renderTableData = () => {

        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

        let ArrayData = []
        for (var i = 0; i < currentRows.length; i++) {
            ArrayData.push(currentRows[i])
          }
        
        return ArrayData.map((row,index)=> (
            <tr key={index}>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-center" : "table-danger align-middle text-center"}>{row.CODIGODEVENTA}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-center" : "table-danger align-middle text-center"}>{row.DOCCLIENTE}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-left" : "table-danger align-middle text-left"}>{row.CLIENTE}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-center" : "table-danger align-middle text-center"}>{row.DOCVENDEDOR}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-left" : "table-danger align-middle text-left"}>{row.VENDEDOR}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-center" : "table-danger align-middle text-center"}>{row.NROGUIAREMISION}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-center" : "table-danger align-middle text-center"}>{row.NROCROMPROBANTE}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-center" : "table-danger align-middle text-center"}>{formatearNumeroASoles(row.MONTOFINAL)}</td>
                <td className={row.ESTADO === "ACTIVO" ? "table-success align-middle text-center" : "table-danger align-middle text-center"}>
                    { row.ESTADO === "ACTIVO" ? <img className="cursorPointer" src={imgDelete} onClick={() => loadModalDeleteConfirmation(row)}/> : row.ESTADO }
                </td> 
                
                
            </tr>
        ))
    };

    const loadModalDeleteConfirmation = (row) =>{
        setDataForSale(row)

        if(row.NROCROMPROBANTE.includes("B")){
            document.querySelector(".modalDeleteSale").querySelector("p").innerHTML = "Estás a un paso de anular la <b>BOLETA</b>, recuerda que una vez anulada ya no se podrá recuperar."
        }else{
            document.querySelector(".modalDeleteSale").querySelector("p").innerHTML = "Estás a un paso de anular la <b>FACTURA</b>, recuerda que una vez anulada ya no se podrá recuperar."
        }
        document.querySelector(".modalDeleteSale").style.display = "flex"
    }

    const modalCloseSale = () => {
        document.querySelector(".modalDeleteSale").style.display = "none"
    }

    const saveDeleteConfirmationSale = () =>{
        setDeleteSale(dataForsale,window.sessionStorage.getItem('utkn'))
        document.querySelector(".modalDeleteSale").style.display = "none"
        window.location.reload()
    }

    const setDeleteSale = async(dataForsale,userId)=>{
        const dataInput = { 
            dataForsale: dataForsale,
            userId: userId
          };
        const   options =   {
                                method: 'POST',
                                headers: {
                                            'Content-Type': 'application/json'
                                            },
                                body: JSON.stringify(dataInput)
                            };
        try {
            //const response = await fetch('http://localhost:4000/api/setDeleteSale',options);
            const response = await fetch(ConexionAPI.conexion+'/api/setDeleteSale',options);
            const data = await response.json();
            console.log(data)
            return true
        } catch (error) {
            console.error(error);
        }                    
    }

    return (
        <>
            <Navbar />
            <h1 className="text-center mt-3 mb-3">LISTAR VENTAS</h1>
            {tableData && 
            <div className="container">
                <div className="table-responsive card mb-4">
                    <Table bordered className="m-0 fs-10-px">
                        <thead className="table-dark text-center">
                            <tr>
                                <th>ID VENTA</th>
                                <th>DOC. CLIENTE</th>
                                <th>CLIENTE</th>
                                <th>DOC. VENDEDOR</th>
                                <th>VENDEDOR</th>
                                <th>NRO. REMISION</th>
                                <th>NRO. COMPROBANTE</th>
                                <th>MONTO TOTAL</th>
                                <th>ESTADO</th>
                            </tr>
                        </thead>
                        <tbody>
                            { renderTableData() }
                        </tbody>
                    </Table>
                </div>
                
                <Pagination className='justify-content-center mt-2'>
                    <Pagination.Prev
                        onClick={() => handlePaginationClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(Math.ceil(tableData.length / rowsPerPage)).keys()].map((pageNumber) => (
                        <Pagination.Item
                        key={pageNumber}
                        active={pageNumber + 1 === currentPage}
                        onClick={() => handlePaginationClick(pageNumber + 1)}
                        >
                        {pageNumber + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => handlePaginationClick(currentPage + 1)}
                        disabled={currentPage === Math.ceil(tableData.length / rowsPerPage)}
                    />
                </Pagination>
            </div>
            ||
            <div className="container d-flex justify-content-center flex-wrap">
                <div className="bodyInformation mt-3">
                    <b>En esta sección podras listar y anular los comprobantes emitidos.</b>
                </div>
                <input className='btn btn-warning btnLogin w-75' type="button" value="Listar ventas" onClick={render}>
                </input>
            </div>
            }
            <div className="modalDeleteSale">
                <div className="modalFront">
                    <span id="btnCloseModal" onClick={modalCloseSale}>&times;</span>
                    <div className="modalBody">
                        <h5>Confirmar anulación de comprobante en la <b>SUNAT</b></h5>
                        <p className="fs-10-px"></p>
                        <input id="idBtnFinalizar" type="submit" value="Anular" className="w-100" onClick={() => saveDeleteConfirmationSale()}></input>
                    </div>
                </div>
            </div>
        </>
    )
}
