import React from "react";
import { Navbar } from "../ui/navbar";
import imgSearch from '../../img/search.png';
import imgAdd from '../../img/plus-circle.svg';
import imgDelete from '../../img/trash.svg';
import imageConfirmation from '../../img/confirmation.gif'
import imageError from '../../img/error.gif'
import imageAlert from '../../img/alert.gif'
import imageTicket from '../../img/boleta.png'
import imageBill from '../../img/factura.png'
import ConexionAPI from "../../conexion/conexion.json"


import 'bootstrap/dist/css/bootstrap.min.css';

export const RegisterSale = () => {
    const imageClient = require.context('../../img/users', false, /\.(png|jpe?g|svg)$/);

    // Variables de estado
    const [errorMessageClient, setErrorMessageCliente] = React.useState();
    const [errorMessageStock, setErrorMessageStock] = React.useState();

    const [sectionFilterClient, setSectionFilterClient] = React.useState(true);
    const [sectionDataClient, setSectionDataClient] = React.useState();
    const [saveDataClient, setSaveDataClient] = React.useState("");
    const [sectionFilterProduct, setSectionFilterProduct] = React.useState();
    const [sectionDataProduct, setSectionDataProduct] = React.useState();
    const [sectionSummary, setSectionSummary] = React.useState(false);
    const [sectionTableFinal, setSectionTableFinal] = React.useState();
    const [costFinal, setCostFinal] = React.useState();
    const [sectionButtonContinue, setSectionButtonContinue] = React.useState(true);
    const [sectionSendSale, setSectionSendSale] = React.useState(false);
    const [selectedOptionTypeBuy, setSelectedOptionTypeBuy] = React.useState("");
    const [selectedOptionSend, setSelectedOptionSend] = React.useState("");
    const [directionFinal, setDirectionFinal] = React.useState("");
    const [referencesFinal, setReferencesFinal] = React.useState("");
    const [actionTable,setActionTable] = React.useState(true);
    const [searchResultsProduct, setSearchResultsProduct] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [dataDistrict, setDataDistrict] = React.useState([]);
    const [nameDistrict, setNameDistrict] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState('');
    const [checkTypeSale, setCheckTypeSale] = React.useState(true);
    const [dataDrive, setDataDrive] = React.useState("");
    const [dataGift, setDataGift] = React.useState("");
    const [discount, setDiscount] = React.useState("");
    const [dataDateSend, setDataDateSend] = React.useState("");
    const [weigth, setWeight] = React.useState("");
    const [placa, setPlaca] = React.useState("");
    const [typeBoucher, setTypeBoucher] = React.useState("");

    

    //Fin de variables globales
    

    // Realizamos la busqueda del cliente
    const validateClient = (e) =>{
        e.preventDefault();
        if(e.target.document.value.length >= 8){
            getClient(e.target.document.value)
        }else{
            setErrorMessageCliente("Ingresar de 8 a 11 caracteres.")
        }
    }

    // Invocamos al API para obtener cliente
    const getClient = async(numberDocument)=>{
        const data = { 
            DNIRUC:  numberDocument.toString()
          };
          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          };
          fetch(ConexionAPI.conexion+'/api/getClient', options)
          .then(response => response.json())
          .then(data => {
                           if(data.length > 0){
                                setErrorMessageCliente()
                                setSectionFilterClient(false)
                                setSectionDataClient(data)
                                setSectionFilterProduct(true)
                           }else{
                                setErrorMessageCliente("No se encontro al cliente, por favor registrarlo.")
                           }
                        }
                );  
    }

    function validateProduct(id,name){
        setSearchTerm(name);
        document.querySelector("#idSearchProduct").value = name;
        setSearchResultsProduct("")
        getProductXid(id)
    }

    const handleOptionChangeSaleMayorMenor = (e)=>{
        setSelectedValue(e.target.value);
    }

    const getProductXid = async(id)=>{
        const data = { 
            id: id ,
            };
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
            };
            fetch(ConexionAPI.conexion+'/api/getProductXid', options)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                            if(data.length === 1){
                                setSectionDataProduct(data);
                            }
                        }
                );  
    }

    // Cargamos la tabla
    const setTable = (e) =>{
        e.preventDefault()
        let cant = e.target.cant.value;
        if(cant){
            if(selectedValue !==""){
                if(!sectionTableFinal){
                    const newArray = sectionDataProduct.map(item => {
                        return {...item, catFinal: cant}
                    });
                    setSectionTableFinal(newArray)
                    addCostTable(newArray)
                }else{
                    let valiateProduct = (filterProductInTable(sectionTableFinal,sectionDataProduct[0].codigo))[0]
                    if(valiateProduct && valiateProduct !== 0){
                        if(sectionTableFinal.length === 1){
                            const newArray = sectionDataProduct.map(item => {
                                return {...item, catFinal: cant}
                            });
                            deleteProduct(valiateProduct.id)
                            setSectionTableFinal(newArray)
                            addCostTable(newArray)
                        }else{              
                            const newArray = sectionDataProduct.map(item => {
                                return {...item, catFinal: cant}
                            });              
                            const nA = deleteProduct(valiateProduct.id)
                            nA.push(newArray[0])
                            setSectionTableFinal(nA)
                            addCostTable(nA)
                        }
                    }else{
                        const newArray = sectionDataProduct.map(item => {
                            return {...item, catFinal: cant}
                        });
                        sectionTableFinal.push(newArray[0])
                        addCostTable(sectionTableFinal)
                    }
                   
                }

                setSearchTerm("")
                setSectionSummary(true)
                setSectionDataProduct(false)
                setErrorMessageStock();
                setCheckTypeSale(false);
            }else{
                loadModalRegisterSale(1,"ALERTA","En necesario colocar el tipo de venta.",false)
            }
        }else{
            setErrorMessageStock("Ingresar una cantidad.");
        }

    }

    // Sumar columnas de la tabla
    const addCostTable = (array) => {
        let costFinal = 0;
        for(let x = 0 ; x < array.length ; x++){
            if(selectedValue == 2){
                costFinal += parseFloat(array[x].catFinal) * parseFloat(array[x].precio_promocional)
            }
            if(selectedValue == 1){
                costFinal += parseFloat(array[x].catFinal) * parseFloat(array[x].precio)
            }
        }
        setCostFinal(costFinal)
    }

    const deleteProduct = (id) => {
        const newArray = sectionTableFinal.filter((item,index) => item.id !== id);
        if(sectionTableFinal){
            setSectionTableFinal(newArray)
        }
        addCostTable(newArray)
        return newArray
    }

    // Buscar producto ya agregado
    const filterProductInTable = (array,cod) =>{
        let response =  array.filter((element) => { return element.codigo == cod })
        if(response.length === 1){
            return response
        }else{
            return 0
        }
    }

    // Paso final de la venta
    const saveTableForSale = ()=> {
        setSaveDataClient(sectionDataClient)
        setSectionDataClient()
        setSectionFilterProduct()
        setSectionButtonContinue(false)
        setSectionSendSale(true)
        setActionTable(false)
    }

    const handleOptionChangeTypeForSale = (e) => {
        setSelectedOptionTypeBuy(e.target.value);
    };

    const handDirectionFinal = (e) => {
        setDirectionFinal(e.target.value);
    };

    const handelFechaEnvio = (e) => {
        e.preventDefault()
        setDataDateSend(e.target.value);
    };

    const handWight = (e) => {
        setWeight(e.target.value);
    };

    const handPlaca = (e) => {
        setPlaca(e.target.value);
    };

    
    const handleSelectDriver = (e) =>{
        e.preventDefault()
        if(e.target.value > 0){
            document.querySelector("#idSelectDefaultDiver").style.display = "none";
        }
        setDataDrive(e.target.value)
    }

    const handleSelectGift = (e) =>{
        e.preventDefault()
        setDataGift(e.target.value)
    }

    const handleSelectDiscount = (e) =>{
        e.preventDefault()
        console.log(e.target.value)
        setDiscount(e.target.value)
    }

    const handReferencesFinal = (e) => {
        setReferencesFinal(e.target.value);
    };

    const handDistrict = (idUbigeo,name) => {
        setSelectedOptionSend(idUbigeo);
        document.querySelector("#idResultPreFilterDistrict").style.display = "none"
        setNameDistrict(name);
    }

    const sendSale = () =>{

        const sendArrayToApi = sectionTableFinal
        
        let arrayForSale = [{ 
                                metodoPago: selectedOptionTypeBuy,
                                codigoUbigeo: selectedOptionSend,
                                direccionEnvio: directionFinal,
                                conductor: dataDrive,
                                regalo: dataGift,
                                fechaEnvio: dataDateSend,
                                comentario: referencesFinal,
                                tipoVenta: selectedValue,
                                tipoComprobante: typeBoucher,
                                peso: weigth,
                                descuento: discount,
                                placa: placa,
                            }]

        sendSaleAPI(saveDataClient,sendArrayToApi,arrayForSale)

    }

    const sendSaleAPI = async(client,sale,send)=>{
        document.querySelector(".modalSale").style.display = "none";    
        document.querySelector("#contenedor").style.display = "flex"
        const data = { 	
            dataClient: client,
            dataSale: sale,
            dataForSend: send,
            costoTotal: costFinal,
            vendedor:window.sessionStorage.getItem('utkn')
        }
        const options = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data)
                        };
        console.log(data)
        fetch(ConexionAPI.conexion+'/api/setSale', options)
            .then(response => response.json())
            .then(data => {
                if(data.message.includes("satisfactoriamente")){
                    document.querySelector("#contenedor").style.display = "none"
                    loadModalRegisterSale(2,"FELICIDADES",data.message,false)
                }else{
                    document.querySelector("#contenedor").style.display = "none"
                    loadModalRegisterSale(0,"LO SENTIMOS",data.message,false)
                }
            })
            .catch(error => console.error(error));
    }

    // Funciones genericas, validar que solo se ingrese numero
    const validateTypeNumber = (e)=>{
        const keyCode = e.which || e.keyCode;
        const tecla = String.fromCharCode(keyCode);
        if (!/^\d+$/.test(tecla)) {
          e.preventDefault();
        }
    }

    function modalCloseRegisterSale(){
        document.querySelector(".modalStatus").style.display = "none";

    }

    function loadModalSale(){
        const sendArrayToApi = sectionTableFinal

        if(sendArrayToApi &&
            selectedOptionTypeBuy && 
            selectedOptionSend &&
            directionFinal &&
            referencesFinal &&
            dataDrive && dataDateSend && weigth && placa ){
                
                
            const modalSaleFinal = document.querySelector(".modalSale")
            modalSaleFinal.style.display = "flex";
            
            const btnComprobante = modalSaleFinal.querySelectorAll(".selectComprobanteOff")

            console.log(btnComprobante)

            if(btnComprobante.length > 1){
                btnComprobante.forEach((element,index)=>{
                    element.addEventListener("click",()=>{
                        if(index == 0){
                            btnComprobante[0].className = "col-lg-4 selectComprobanteOn"
                            btnComprobante[1].className = "col-lg-4 selectComprobanteOff"
                            modalSaleFinal.querySelector("#idBtnFinalizar").className = "w-100"
                            setTypeBoucher("BBB1")
                        }
                        if(index == 1){
                            btnComprobante[0].className = "col-lg-4 selectComprobanteOff"
                            btnComprobante[1].className = "col-lg-4 selectComprobanteOn"
                            modalSaleFinal.querySelector("#idBtnFinalizar").className = "w-100"
                            setTypeBoucher("FFF1")
                        }
                    })
                })

            }else{
                btnComprobante[0].className = "col-lg-4 selectComprobanteOn"
                modalSaleFinal.querySelector("#idBtnFinalizar").className = "w-100"
                setTypeBoucher("BBB1")
            }
        }else{
            loadModalRegisterSale(1,"ALERTA","En necesario completar: <br> * Metodo de pago. <br> * Datos de envío. <br> * Agregar comentarios. ",false)
        }
    }

    function modalCloseSale(){
        document.querySelector(".modalSale").style.display = "none";
        document.querySelector(".modalSale").querySelectorAll(".col-lg-4").forEach((element)=>{
            element.className = "col-lg-4 selectComprobanteOff"
        })
        document.querySelector("#idBtnFinalizar").className = "w-100 btnOff"
        setTypeBoucher("")
    }

    const loadModalRegisterSale = (codMessage, titleMessage, message, stateButton) => {
        if(codMessage === 0){
            document.querySelector("#idImage").src = imageError;
            document.querySelector("#idTitle").innerHTML = titleMessage;
            document.querySelector("#idMessage").innerHTML= message;
        }

        if(codMessage === 1){
            document.querySelector("#idImage").src = imageAlert;
            document.querySelector("#idTitle").innerHTML = titleMessage;
            document.querySelector("#idMessage").innerHTML= message;
        }

        if(codMessage === 2){
            document.querySelector("#idImage").src = imageConfirmation;
            document.querySelector("#idTitle").innerHTML = titleMessage;
            document.querySelector("#idMessage").innerHTML= message;
            
            document.querySelector(".modalStatus").addEventListener("click",()=>{
               window.location.reload()
            })
        }

        document.querySelector(".modalStatus").style.display = "flex";

    }

    // test
    const handleSearch = async (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
 
        if (searchTerm) {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({caracter:searchTerm})
                };
          const response = await fetch(ConexionAPI.conexion+'/api/getProduct',options);
          const data = await response.json();
          setSearchResultsProduct(data);
        } else {
          setSearchResultsProduct([]);
        }
    };

    const handleSearchDistric = async(e) =>{
        const nameDistrict = e.target.value;
        setNameDistrict(nameDistrict);
 
        if (nameDistrict) {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({caracter:nameDistrict})
                };
          const response = await fetch(ConexionAPI.conexion+'/api/getDistrict',options);
          const data = await response.json();
          setDataDistrict(data);
        } else {
          setDataDistrict([]);
        }
    }

    return(
    <>
        <Navbar />
        <div className="container">

            <h1 className="text-center mt-3">REGISTRAR LA VENTA</h1>
            {sectionFilterClient && 
            <div>
                <div className="row">
                    <div className="col-lg-4">
                        <form className="input-group" onSubmit={validateClient}>
                            <input onKeyPress={validateTypeNumber} maxLength="11" pattern="[0-9]+" type="telf" className="form-control" placeholder="Ingresar documento" name="document"></input>
                            <button className="btn btn-warning btn-outline-warning" type="submit" id="button-addon2">
                                <img className="rounded mx-auto d-block" src={imgSearch} width="20"/>
                            </button>
                        </form>
                        {errorMessageClient && <span className="errorMessage">{errorMessageClient}</span>}
                    </div>
                </div>
                <div className="bodyInformation mt-3">
                    <b>Para empezar a realizar la venta es necesario ingresar el documento del cliente.</b>
                </div>
            </div>
            }
            {sectionDataClient && 
                <div className="borderGroup mt-4">
                    <h5 className="titleGroup">Datos de cliente:</h5>
                    <div className="row d-flex align-items-center ">
                        <div className="col-lg-3 text-center">
                            <img className="imageCicle" src={imageClient('./'+sectionDataClient[0].DNIRUC +'.png')}/>
                        </div>
                        <div className="col-lg-4">
                            <span><b>Documento:</b> { sectionDataClient[0].DNIRUC } </span><br></br>
                            <span><b>Nombres:</b> { sectionDataClient[0].nombres } </span><br></br>
                            <span><b>Apellidos:</b> { sectionDataClient[0].apellido_paterno + " " + sectionDataClient[0].apellido_materno } </span>
                        </div>
                        <div className="col-lg-5">
                            <span><b>Celular:</b> { sectionDataClient[0].celular } </span><br></br>
                            <span><b>Correo:</b> { sectionDataClient[0].correo } </span><br></br>
                            <span><b>Direccion:</b> { sectionDataClient[0].direccion } </span>
                        </div>
                    </div>
                </div>
            }
            {sectionFilterProduct && 
                <div className="borderGroup mt-4 mb-4" >
                    <h5 className="titleGroup">Filtrar por producto:</h5>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-4">
                            <input id="idSearchProduct" className="form-control" type="text" autoComplete="off" placeholder="Ingresar el nombre del producto" value={searchTerm} onChange={handleSearch} />
                            {searchResultsProduct.length > 0 && (
                                <div id="idResultPreFilterProducts" className="selectAndOptions">
                                {searchResultsProduct.map((result,index) => (
                                    <div key={index} onClick={()=>(validateProduct(result.id,result.descripcion))}>{result.descripcion}</div>
                                ))}
                                </div>
                            )}
                            {checkTypeSale && 
                            <div>
                                <h6 className="mt-3">Tipo de venta:</h6>
                                <div className='row form-check form-check-inline grid'>
                                    <label className='col form-check-label'>
                                        <input className="form-check-input"
                                        type="radio"
                                        value="1"
                                        checked={selectedValue === '1'}
                                        onChange={handleOptionChangeSaleMayorMenor}
                                        />
                                        VENTA POR MENOR
                                    </label>
                                    <label className='col form-check-label'>
                                        <input className="form-check-input"
                                        type="radio"
                                        value="2"
                                        checked={selectedValue === '2'}
                                        onChange={handleOptionChangeSaleMayorMenor}
                                        />
                                        VENTA POR MAYOR
                                    </label>
                                </div>
                            </div>
                            }
                          
                        </div>
                        {sectionDataProduct && 
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <span><b>Nombre: </b>{ sectionDataProduct[0].nombre }</span><br></br>
                                        <span><b>Linia: </b>{ sectionDataProduct[0].linia }</span><br></br>
                                        <span><b>Cantidad: </b>{ sectionDataProduct[0].stock }</span><br></br>
                                    </div>
                                    <div className="col-lg-6">
                                        <span><b>Agregar producto:</b></span>
                                        <form className="input-group mb-3" onSubmit={setTable}>
                                            <input type="tel" onKeyPress={validateTypeNumber} maxLength="2" className="form-control" name="cant" placeholder="00"></input>
                                            <button className="btn btn-warning btn-outline-warning" type="submit" id="button-addon2">
                                                <img className="rounded mx-auto d-block" src={imgAdd}/>
                                            </button>
                                        </form>
                                        {errorMessageStock && <div className="errorMessage"> {errorMessageStock} </div>}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {sectionSummary &&
                <div>
                    <h4 className="mb-4">RESUMEN DE PEDIDO:</h4>
                    <div className="table-responsive card">
                        <table className="table table-bordered table-striped m-0">
                            <thead className="table-dark">
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Linia</th>
                                    <th>Cantidad</th>
                                    <th>Precio unitario</th>
                                    <th>Total</th>
                                    { actionTable &&
                                        <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {sectionTableFinal.map((item,index) => (
                                    <tr key={index}>
                                        <td className="text-center">{item.codigo}</td>
                                        <td>{item.nombre}</td>
                                        <td>{item.linia}</td>
                                        <td className="text-center">{parseFloat(item.catFinal)}</td>
                                        <td className="text-center">{ selectedValue == 2 ? parseFloat(item.precio_promocional) : parseFloat(item.precio)}</td>
                                        <td className="text-center"><b>{selectedValue == 2 ? parseFloat(item.catFinal) * parseFloat(item.precio_promocional) : parseFloat(item.catFinal) * parseFloat(item.precio) }</b></td>
                                        { actionTable &&
                                            <td className="text-center">
                                                <img className="cursorPointer" src={imgDelete} onClick={() => deleteProduct(item.id)} />
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center mt-3 mb-3">Monto total es: <b>{parseFloat(costFinal)}</b></div>
                    {sectionButtonContinue && 
                    <>
                        <div className="row d-flex justify-content-end">
                            <p className="col-lg-3">
                                <input type="submit" value="Continuar" className="w-100" onClick={()=>saveTableForSale()}></input>
                            </p>
                        </div>
                    </>
                    }
                </div>
            }
            {sectionSendSale &&
            <>
                <hr></hr>
                <div className="row mt-3">
                    <h4>PAGO Y ENVIO:</h4>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="borderGroup mt-4">
                            <h5 className="titleGroup">Metodo de pago:</h5>
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="chkMetodoPago" id="idChkEfectivo" value="EFECTIVO" onChange={handleOptionChangeTypeForSale}></input>
                                    <label className="form-check-label" htmlFor="idChkEfectivo">EFECTIVO</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="chkMetodoPago" id="idChkDeposito" value="DEPOSITO" onChange={handleOptionChangeTypeForSale}></input>
                                    <label className="form-check-label" htmlFor="idChkDeposito">DEPÓSITO</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="chkMetodoPago" id="idChkTcDebito" value="TD" onChange={handleOptionChangeTypeForSale}></input>
                                    <label className="form-check-label" htmlFor="idChkTcDebito">TARJETA DE DÉBITO</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="chkMetodoPago" id="idChkTcCredito" value="TC" onChange={handleOptionChangeTypeForSale}></input>
                                    <label className="form-check-label" htmlFor="idChkTcCredito">TARJETA DE CRÉDITO</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="chkMetodoPago" id="idChkYapePlin" value="YAPEPLIN" onChange={handleOptionChangeTypeForSale}></input>
                                    <label className="form-check-label" htmlFor="idChkYapePlin">YAPE / PLIN</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="chkMetodoPago" id="idChkMixto" value="COMBINADO" onChange={handleOptionChangeTypeForSale}></input>
                                    <label className="form-check-label" htmlFor="idChkMixto">COMBINADO</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="borderGroup mt-4">
                            <h5 className="titleGroup">Datos de envío:</h5>
                            <div className="row mt-3">
                                <div className="col-lg-6">
                                    <label htmlFor="inputSelect">Distrito:</label>
                                    <input id="idSearchDistrict" className="form-control" type="text" autoComplete="off" placeholder="Ingresar el distrito" value={nameDistrict} onChange={handleSearchDistric} />
                                    {dataDistrict.length > 0 && (
                                        <div id="idResultPreFilterDistrict" className="selectAndOptions">
                                        {dataDistrict.map((result,index) => (
                                            <div key={index} onClick={()=>(handDistrict(result.id_ubigeo,result.descripcion))}>{result.descripcion}</div>
                                        ))}
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="idDireccion">Dirección:</label>
                                    <input className="form-control" type="tel" id="idDireccion" autoComplete="off" name="direccion" placeholder="Ingresar la dirección" onChange={handDirectionFinal}></input>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-3">
                                    <label htmlFor="idDireccion">Conductor:</label>
                                    <select className="form-select form-control" onChange={handleSelectDriver}>
                                        <option value="0" id="idSelectDefaultDiver">--SELECCIONAR--</option>
                                        <option value="WILY">WILY TITO DE LA CRUZ BOLAÑOS</option>
                                        <option value="JESUS">JESUS PAUCAR CORZO</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="idDireccion">Placa:</label>
                                    <input className="form-control" type="tel" id="idDireccion" autoComplete="off" maxLength="7" name="direccion" placeholder="Ingresar placa" onChange={handPlaca}></input>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="idDireccion">Fecha de envío:</label>
                                    <input className="form-control" type="date" min={new Date().toISOString().slice(0, 10)} id="idDireccion" name="direccion" onChange={handelFechaEnvio}></input>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="idDireccion">Peso en KG:</label>
                                    <input className="form-control" type="tel" id="idDireccion" autoComplete="off" name="direccion" onKeyPress={validateTypeNumber} placeholder="Ingresar peso" onChange={handWight}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 mt-4">
                        <div className="borderGroup">
                            <h5 className="titleGroup">Agregar regalo o cupones:</h5>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label htmlFor="idDireccion">Regalo:</label>
                                    <select className="form-select form-control" onChange={handleSelectGift}>
                                        <option value="0" id="idSelectDefaultGift">Seleccionar...</option>
                                        <option value="1">PROTEINA</option>
                                        <option value="2">GUANTES</option>
                                    </select>
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="idDireccion">Cupón:</label>
                                    <select className="form-select form-control" onChange={handleSelectDiscount}>
                                        <option value="0" id="idSelectDefaultGift">Seleccionar...</option>
                                        <option value="100">S/ 100 Desc.</option>
                                        <option value="300">S/ 300 Desc.</option>
                                        <option value="500">S/ 500 Desc.</option>
                                        <option value="1000">S/ 1,000 Desc.</option>
                                        <option value="2000">S/ 2,000 Desc.</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-8 mt-4">
                        <div className="borderGroup">
                            <h5 className="titleGroup">Agregar comentarios:</h5>
                            <div className="form-floating">
                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea" onChange={handReferencesFinal}></textarea>
                                <label htmlFor="floatingTextarea">Comentarios</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end mt-3">
                    <p className="col-lg-3">
                        <input type="submit" value="Finalizar" className="w-100" onClick={loadModalSale} ></input>
                    </p>
                </div>
            </>
            }
            <div className="modalSale">
                <div className="modalFront">
                    <span id="btnCloseModal" onClick={modalCloseSale}>&times;</span>
                    <div className="modalBody">
                        <h6><b>COMPROBANTE</b></h6>
                        {saveDataClient && 
                        <div className="row d-flex justify-content-center gap-3 mb-3 mt-3">
                            <div className="col-lg-4 selectComprobanteOff">
                                <img src={imageTicket} width="50" className="mt-2"/>
                                Boleta
                            </div> 
                            { saveDataClient[0].tipo_documento == "RUC" ? <div className="col-lg-4 selectComprobanteOff">
                                <img src={imageBill} width="50" className="mt-2"/>
                                Factura
                            </div>: <div></div>}
                        </div>
                        }
                        <input id="idBtnFinalizar" type="submit" value="Finalizar" className="w-100 btnOff" onClick={() => sendSale()}></input>
                    </div>
                </div>
            </div>
        <div className="modalStatus">
            <div className="modalFront">
                <span id="btnCloseModal" onClick={modalCloseRegisterSale}>&times;</span>
                <div className="modalBody">
                    <img id="idImage" width="60"/>
                    <p><b id="idTitle"></b></p>
                    <p id="idMessage"></p>
                </div>
            </div>
        </div>
        <div id="contenedor">
            <div className="contenedor-loader">
                <div className="loader"></div>
            </div>
            <div className="cargando">Cargando...</div>
        </div>
        </div>

    </>
   )
}
