import { NavLink } from 'react-router-dom';
import "../../css/iu/navbar.css"
import logoSinFondoCronos from "../../img/logoconcronoslifesinfondo.png"
import CryptoJS from 'crypto-js';
import sinFoto from '../../img/SinFoto.png'

export const Navbar = () => {

    if(!window.sessionStorage.getItem('tkn') || !window.sessionStorage.getItem('dtkn')){
        window.open("/loginUser","_self")
    }


    const images = require.context('../../img/users', false, /\.(png|jpe?g|svg)$/);

    const secretPass = 'secretPass';
    const nameUser = window.sessionStorage.getItem('tkn')
    const documentUser = window.sessionStorage.getItem('dtkn')
    const nameUserDecrypt = CryptoJS.AES.decrypt(nameUser, secretPass).toString(CryptoJS.enc.Utf8);
    const documentUserDecrypt = CryptoJS.AES.decrypt(documentUser, secretPass).toString(CryptoJS.enc.Utf8);
    
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
            <img src={ logoSinFondoCronos } height="40" />
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
                    <li className="nav-item mt-1" >
                        <NavLink className="nav-link" to="/home">
                            <p className={window.location.href.includes("home") ? "navNotPalote fs-14 b" : "navNotPalote fs-14"}>INICIO</p>
                        </NavLink>
                    </li>
                    <li className='nav-item mt-1'> 
                        <NavLink className="nav-link" to="/registerUser">
                            <p className={window.location.href.includes("registerUser") ? "navNotPalote fs-14 b" : "navNotPalote fs-14"}>REGISTRAR CLIENTE</p>
                        </NavLink>
                    </li>
                    <li className="nav-item dropdown mt-1">
                        <a className= {window.location.href.includes("registerSale") || window.location.href.includes("listSale") ? 
                        "navNotPalote fs-14 b nav-link dropdown-toggle" : 
                        "navNotPalote fs-14 nav-link dropdown-toggle"} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            VENTAS
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <NavLink className="dropdown-item nav-link" to="/registerSale">
                                    <p className={window.location.href.includes("registerSale") ? "navNotPalote fs-14 b" : "navNotPalote fs-14"}>REGISTRAR VENTA</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="dropdown-item nav-link" to="/listSale">
                                    <p className={window.location.href.includes("listSale") ? "navNotPalote fs-14 b" : "navNotPalote fs-14"}>LISTAR VENTA</p>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item mt-1">
                        <NavLink className="nav-link" to="/stockProductos">
                            <p className={window.location.href.includes("stockProductos") ? "navNotPalote fs-14 b" : "navNotPalote fs-14"}>LISTAR PRODUCTOS</p>
                        </NavLink>
                    </li>

                </ul>

                <div className="d-flex navbarUsuario">
                    <img className="imagenUsuario" src={ images('./'+documentUserDecrypt+'.png') }/>
                    <p className="navPalote fs-10">{ nameUserDecrypt }</p>
                </div>
            
            </div>
        </div>
        </nav>
            <hr></hr>
        </div>
        
    )
}