import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes,Route } from 'react-router-dom';
import { Navbar } from "../components/ui/navbar"
import { LoginUser } from "../components/login/loginUser";
import { Home } from "../components/home/home";
import { RegisterUser } from "../components/registerUser/registerUser";
import { RegisterSale } from "../components/registerSale/registerSale";
import { StockProductos } from "../components/sotckProducts/stockProducts";
import { ListSale } from "../components/listSale/listSale";


export default function AppRouter() {

  return (
    <Router>
      <div>
        <Routes>
          <Route  path="" element={ <LoginUser /> }/>
          <Route  path="/" element={ <LoginUser /> }/>
          <Route  path="/loginUser" element={ <LoginUser /> }/>
          <Route  path="/home" element={ <Home /> }/>
          <Route  path="/registerUser" element={ <RegisterUser /> }/>
          <Route  path="/registerSale" element={ <RegisterSale /> }/>
          <Route  path="/listSale" element={ <ListSale /> }/>
          <Route  path="/stockProductos" element={ <StockProductos /> }/>
        </Routes>
      </div>
    </Router>
  );
}