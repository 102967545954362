import React from "react";
import "../../css/home/home.css"
import imgRegistrarCliente from "../../img/REGISTRARUSUARIOS.png"
import imgRegistrarVenta from "../../img/REGISTRARVENTA.png"
import ConexionAPI from "../../conexion/conexion.json"
import { Navbar } from "../ui/navbar";


export const Home = ()=>{
    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row text-center d-flex justify-content-center">
                    <div className="col-lg-3 mt-3 mb-3 d-flex justify-content-center">
                        <div className="card">
                            <img src={ imgRegistrarCliente } className="card-img-top" height="220"/>
                            <div className="card-body">
                                <h5 className="card-title">Registra clientes</h5>
                                <p className="card-text fs-14">
                                    En este módulo se podrá registrar a los nuevos clientes para poder realizar la venta.
                                </p>
                                <a href="/registerUser" className="btn btn-warning">AGREGAR CLIENTE</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-3 mb-3 d-flex justify-content-center">
                        <div className="card">
                            <img src={ imgRegistrarVenta } className="card-img-top" height="220" />
                            <div className="card-body">
                                <h5 className="card-title">Registrar ventas</h5>
                                <p className="card-text fs-14">
                                    En este módulo se podrá realizar la venta de los productos usando solo el DNI, CE o RUC del cliente.
                                </p>
                                <a href="/registerSale" className="btn btn-warning">REALIZAR VENTA</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}