import { React } from "react";
import '../../css/registerUser/registerUser.css';
import { Navbar } from "../ui/navbar";
import CryptoJS from 'crypto-js';
import imageConfirmation from '../../img/confirmation.gif'
import imageError from '../../img/error.gif'
import imageAlert from '../../img/alert.gif'
import ConexionAPI from "../../conexion/conexion.json"


export const RegisterUser = ()=>{

    function modalClose(){
        document.querySelector(".modalStatus").style.display = "none";
    }

    const loadModal = (codMessage, titleMessage, message, stateButton) => {
        if(codMessage === 0){
            document.querySelector("#idImage").src = imageError;
            document.querySelector("#idTitle").innerHTML = titleMessage;
            document.querySelector("#idMessage").innerHTML= message;
        }

        if(codMessage === 1){
            document.querySelector("#idImage").src = imageAlert;
            document.querySelector("#idTitle").innerHTML = titleMessage;
            document.querySelector("#idMessage").innerHTML= message;
        }

        if(codMessage === 2){
            document.querySelector("#idImage").src = imageConfirmation;
            document.querySelector("#idTitle").innerHTML = titleMessage;
            document.querySelector("#idMessage").innerHTML= message;
            
            document.querySelector(".modalStatus").addEventListener("click",()=>{
               window.location.reload()
            })
        }

        document.querySelector(".modalStatus").style.display = "flex";

    }

    const handleSubmit = e => {
        e.preventDefault();

        let arrayNewUser = {
                                documento: e.target.dni.value,
                                tipoDocumento: e.target.typeDocument.value,
                                tipoCliente: e.target.category.value,
                                nombres: e.target.nombres.value,
                                apellidoPaterno: e.target.apellidoPaterno.value,
                                apellidoMaterno: e.target.apellidoMaterno.value,
                                fechaNacimiento: e.target.fechaNacimiento.value,
                                genero: e.target.genero.value,
                                celular: e.target.celular.value,
                                provincia: e.target.provincia.value,
                                direccion: e.target.direccion.value,
                                correo: e.target.correo.value,
                                foto: e.target.foto.value
        }
        console.log(e.target.foto.files[0])
        if(!e.target.dni.value || e.target.typeDocument.value == 0 ||
           e.target.category.value == 0 || !e.target.nombres.value ||
           !e.target.apellidoPaterno.value || !e.target.apellidoMaterno.value ||
           !e.target.fechaNacimiento.value || e.target.genero.value == 0 || 
           !e.target.celular.value || !e.target.direccion.value ||
           !e.target.correo.value || e.target.provincia.value == 0 ||
           !e.target.foto.value){
            loadModal(1,"ALERTA","Es necesario completar todos los datos para poder realizar el registro del cliente.",false)
        }else {
            validarUsuario(arrayNewUser, e.target.foto.files[0])

            //sendFile(e.target.foto)
        }
    }

    /*const sendFile = async (file2) => {
        console.log(file2)
        const fmData = new FormData();

        fmData.append("files",file2)


        fetch('http://localhost:4000/api/setFile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fmData)
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }*/

    // Validacion de api
    const validarUsuario = async (arrayNewUser,file) => {

        const date = new Date();
        const toDay = new Date(date.getTime() - 5 * 60 * 60 * 1000).toISOString()
        
        const secretPass = 'secretPass';
        const idUser = window.sessionStorage.getItem('utkn')
        const idUserDecrypt = CryptoJS.AES.decrypt(idUser, secretPass).toString(CryptoJS.enc.Utf8);

        const data = { 	"nombres": arrayNewUser.nombres,
                        "apellido_paterno": arrayNewUser.apellidoPaterno,
                        "apellido_materno": arrayNewUser.apellidoMaterno,
                        "DNIRUC": arrayNewUser.documento,
                        "tipo_documento": arrayNewUser.tipoDocumento,
                        "tipo_cliente": arrayNewUser.tipoCliente,
                        "password": "",
                        "fecha_nacimiento": arrayNewUser.fechaNacimiento,
                        "genero": arrayNewUser.genero,
                        "celular": arrayNewUser.celular,
                        "id_provincia": arrayNewUser.provincia,
                        "direccion": arrayNewUser.direccion,
                        "correo": arrayNewUser.correo,
                        "rol": "CLIENTE",
                        "ruta_imagen": arrayNewUser.foto.value,
                        "id_usuario_creacion": idUserDecrypt,
                        "id_usuario_modificacion": idUserDecrypt,
                        "estado": 1
                    }


        // dos
        fetch(ConexionAPI.conexion+'/api/setUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                    if(data[0].mensaje.includes("ERROR")){
                        loadModal(0,"ERROR","Lo sentimos el cliente ya está registrado.",false)
                    }else{
                        sentImageAPI(file, arrayNewUser.documento)
                        document.documentElement.style.setProperty('--content', '"Seleccionar archivo. (PNG)"');
                        loadModal(2,"CORRECTO","Se registro cliente satisfactoriamente.",false)
                    }
                }
            )
            .catch(error => console.error(error));
    }

    const sentImageAPI = async(imgVal,dni)=>{

        let formData = new FormData();
        formData.append('image', imgVal);
        formData.append('DNI', dni);
    
        const options =   {
                                method: 'POST',
                                body: formData
                            };
            
        try {
            const response = await fetch(ConexionAPI.conexion+'/api/saveImage',options);
            const data = await response.json();
            console.log(data);
            
        } catch (error) {
            console.error(error);
        }                    
        
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        document.documentElement.style.setProperty('--content', '"Se agrego la foto: '+file.name+'"');
      };

    const validateTypeNumber = (e)=>{
        const keyCode = e.which || e.keyCode;
        const tecla = String.fromCharCode(keyCode);
        if (!/^\d+$/.test(tecla)) {
          e.preventDefault();
          console.log(keyCode)
        }

    }

    return (
        <>
        <Navbar />
        <div className="container mt-3 mb-5 text-left row m-auto d-flex align-items-center justify-content-center">
            <section>
                <h1>REGISTRAR CLIENTE</h1>
            </section>
            <div id="formRegisterUser" className="borderGroup mt-4 col-sm-8">
                <h5 className="titleGroup">Ingresar datos de cliente:</h5>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <p className="col-lg-4 text-left">
                            <label htmlFor="dni">DNI/CE/RUC:</label>
                            <input className="inputText" type="tel" id="dni" name="dni" placeholder="Ingresa tu documento" maxLength="13" onKeyPress={validateTypeNumber}></input>
                        </p>
                        <p className="col-lg-4">
                            <label>TIPO DE DOCUMENTO:</label>
                            <select className="inputSelect" name="typeDocument" defaultValue={'0'}>
                                <option value="0">Seleccionar</option>
                                <option value="DNI">DNI</option>
                                <option value="RUC">RUC</option>
                                <option value="CE">CARNET DE EXTRANGERIA</option>
                                <option value="PASAPORTE">PASAPORTE</option>
                            </select>
                        </p>
                        <p className="col-lg-4">
                            <label >CATEGORIA:</label>
                            <select className="inputSelect" name="category" defaultValue={'0'}>
                                <option value="0">Seleccionar</option>
                                <option value="PERSONA NATURAL">PERSONA NATURAL</option>
                                <option value="GIMANASIO">GIMANASIO</option>
                            </select>
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-lg-6">
                            <label>NOMBRES:</label>
                            <input className="inputText" type="text" name="nombres" placeholder="Ingresa los nombres" ></input>
                        </p>
                        <p className="col-lg-3">
                            <label>APELLIDO PATERNO</label>
                            <input className="inputText" type="text" name="apellidoPaterno" placeholder="Ingresa el apellido paterno" ></input>
                        </p>
                        <p className="col-lg-3">
                            <label>APELLIDO MATERNO</label>
                            <input className="inputText" type="text" name="apellidoMaterno" placeholder="Ingresa el apellido materno" ></input>
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-lg-4">
                            <label>FECHA DE NACIMIENTO:</label>
                            <input className="inputText" type="date" name="fechaNacimiento" ></input>
                        </p>
                        <p className="col-lg-4">
                            <label>GENERO:</label>
                            <select className="inputSelect" name="genero" defaultValue={'0'}>
                                <option value="0">Seleccionar</option>
                                <option value="Masculino">Masculino</option>
                                <option value="Femenino">Femenino</option>
                            </select>
                        </p>
                        <p className="col-lg-4">
                            <label>CELULAR:</label>
                            <input className="inputText" type="tel" name="celular" placeholder="Ingresa el numero de celular" onKeyPress={validateTypeNumber} maxLength="9"></input>
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-lg-4">
                            <label>PROVINCIA</label>
                            <select className="inputSelect" name="provincia" defaultValue={'0'} >
                                <option value="0">Seleccionar</option>
                                <option value="1">AMAZONAS</option>
                                <option value="2">ANCASH</option>
                                <option value="3">APURIMAC</option>
                                <option value="4">AREQUIPA</option>
                                <option value="5">AYACUCHO</option>
                                <option value="6">CAJAMARCA</option>
                                <option value="7">PROV. CONST. DEL CALLAO</option>
                                <option value="8">CUSCO</option>
                                <option value="9">HUANCAVELICA</option>
                                <option value="10"> HUANUCO</option>
                                <option value="11"> ICA</option>
                                <option value="12"> JUNIN</option>
                                <option value="13"> LA LIBERTAD</option>
                                <option value="14"> LAMBAYEQUE</option>
                                <option value="15"> LIMA</option>
                                <option value="16"> LORETO</option>
                                <option value="17"> MADRE DE DIOS</option>
                                <option value="18"> MOQUEGUA</option>
                                <option value="19"> PASCO</option>
                                <option value="20"> PIURA</option>
                                <option value="21"> PUNO</option>
                                <option value="22"> SAN MARTIN</option>
                                <option value="23"> TACNA</option>
                                <option value="24"> TUMBES</option>
                                <option value="25"> UCAYALI</option>
                            </select>
                        </p>
                        <p className="col-lg-8">
                            <label>DIRECCIÓN</label>
                            <input className="inputText" type="text" name="direccion" placeholder="Ingresar direccion"></input>
                        </p>
                    </div>
                    <div className="row">
                        <p className="col-lg-6">
                            <label>CORREO</label>
                            <input className="inputText" type="email" name="correo" placeholder="Ingresar correo"></input>
                        </p>
                        <p className="col-lg-6">
                            <label htmlFor="foto">AGREGAR IMAGEN:</label>
                            <input className="inputFile" type="file" id="foto" name="foto" accept="image/png" onChange={handleFileChange}></input>
                        </p>
                    </div>
                    <div className="row d-flex justify-content-end">
                        <p className="col-lg-3 mb-0">
                            <input type="submit" value="Registrar" className="w-100"></input>
                        </p>
                    </div>
                </form>
            </div>

            
        </div>
        <div className="modalStatus">
            <div className="modalFront">
                <span id="btnCloseModal" onClick={modalClose}>&times;</span>
                <div className="modalBody">
                    <img id="idImage" width="60"/>
                    <p><b id="idTitle"></b></p>
                    <p id="idMessage"></p>
                </div>
            </div>
        </div>
        </>
    );
}

