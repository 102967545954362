import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Navbar } from "../ui/navbar";
import { Table, Pagination} from 'react-bootstrap'
import inStock from "../../img/InStock.png";
import notStock from "../../img/Notstock.svg";
import alertStock from "../../img/AlertStock.png";
import imgSearch from '../../img/search.png';
import imgPhoto from '../../img/photo-plus.png';
import imgEdit from '../../img/pencil-square.svg';
import '../../css/stockProduct/stockProduct.css';
import CryptoJS from 'crypto-js';
import ConexionAPI from "../../conexion/conexion.json"

const CargarFiltroSubcategorias = () =>{
    const [selectedValue, setSelectedValue] = React.useState('');
    const [valCodigo, setValCodigo] = React.useState('');
    const [valLinia, setValLinia] = React.useState('');
    const [tableData, setTableData] = React.useState(''); // tus datos de la tabla
    
    const [currentPage, setCurrentPage] = React.useState(1);
    const rowsPerPage = 10; // el número de filas por página

    const [renderTableWithInformation, setRenderTableWithInformation] = React.useState(false)
    const images = require.context('../../img/stock', false, /\.(png|jpe?g|svg)$/);

    const secretPass = 'secretPass';
    const rolUser = window.sessionStorage.getItem('rtkn')
    const rolUserDecrypt = CryptoJS.AES.decrypt(rolUser, secretPass).toString(CryptoJS.enc.Utf8);

    const [editingId, setEditingId] = React.useState(null);

    var ArrayData = []

    const loadModal = (nameProduct,codProduct) =>{
        const modal = document.getElementById("modal");
        const closeModalBtn = document.getElementsByClassName("close")[0];
        
        modal.querySelector("#headerModal").innerHTML = nameProduct;
        modal.querySelector("#bodyModal").src = images('./'+codProduct.toLowerCase()+'.png')

        modal.style.display = "block";
        
        closeModalBtn.onclick = function() {
            modal.style.display = "none";
        }
        
        window.onclick = function(event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }
    }

    /*consulta api y respuesta de api*/
    const resulApiStock = async(valText,valLinia,valSubCat)=>{
        const data = { 
                        CODIGO: valText ,
                        NOMBRE: "" ,
                        LINIA: valLinia,
                        SUBCATEGORIA: valSubCat
                     };
    
        const   options =   {
                                method: 'POST',
                                headers: {
                                            'Content-Type': 'application/json'
                                         },
                                body: JSON.stringify(data)
                            };
    
            
        try {
            const response = await fetch(ConexionAPI.conexion+'/api/getStock',options);
            const data = await response.json();
    
            if(response.status == 200){
                handlePaginationClick(1)
                data.length > 0 ? setRenderTableWithInformation(true) : setRenderTableWithInformation(false)
                setTableData(data);
            }
            
        } catch (error) {
            console.error(error);
        }                    
        
    }

    /*radio button*/
    const handleOptionChange = (event) => {
        setSelectedValue(event.target.value);
        setTableData('');
        setRenderTableWithInformation(false)
        if(document.querySelector('.form-control')){
            document.querySelector('.form-control').value = ''
        }
    }

    /**Select**/
    const selectedOptionSelect=(event)=>{
        let valueSelect = event.target.value;
        valueSelect!=0 ? resulApiStock('','',valueSelect) : setTableData('');
    }

    /*formulario de busqueda*/
    const btnBuscarPorLinia=e=>{
        e.preventDefault();
        resulApiStock('',valLinia,'')
    }

    /*formulario de busqueda*/
    const btnBuscarStock=e=>{
        e.preventDefault();
        resulApiStock(valCodigo,'','')
    }



    /**Renderizar filtros**/
    const renderTypeOfFilter=()=>{

        let valueRadio = selectedValue;

        if(valueRadio==1){
            return (
                <>
                    <select className="form-select mt-1" onChange={selectedOptionSelect}>
                        <option value="0">--SELECCIONE--</option>
                        <option value="1">EQUIPO DE GIMNASIO</option>
                        <option value="2">EQUIPO DE CASA</option>
                        <option value="3">FUNCIONAL</option>
                        <option value="4">PESO LIBRE</option>
                        <option value="5">PISO GRAS</option>
                        <option value="6">OTROS</option>
                    </select>
                </>
            )
        }else if(valueRadio==2){            
            return (
                <>
                     <form className="input-group mt-1" onSubmit={btnBuscarPorLinia}>
                        <input type="text" name="linia" className="form-control" placeholder="INGRESAR LINIA" onChange={(e) => setValLinia(e.target.value)}/>
                        <button className="btn btn-warning btn-outline-warning" type="submit" id="button-addon2">
                            <img className="rounded mx-auto d-block" src={imgSearch} width="20"/>
                        </button>
                    </form>
                </>
                )
        }else if(valueRadio==3){            
            return (
                <>
                     <form className="input-group mt-1" onSubmit={btnBuscarStock}>
                        <input type="text" name="codigo" className="form-control" placeholder="INGRESAR CÓDIGO A BUSCAR" onChange={(e) => setValCodigo(e.target.value)}/>
                        <button className="btn btn-warning btn-outline-warning" type="submit" id="button-addon2">
                            <img className="rounded mx-auto d-block" src={imgSearch} width="20"/>
                        </button>
                    </form>
                </>
                )
        }
    }

    /*PAGINATION*/
    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const validateTypeNumber = (e)=>{
        const keyCode = e.which || e.keyCode;
        const tecla = String.fromCharCode(keyCode);
        if (!/^\d+$/.test(tecla)) {
          e.preventDefault();
          console.log(keyCode)
        }

    }

    /*Actualizar stock API*/
    const updateStockAPI = async(id,stock)=>{
        const data = { 
                        ID: id ,
                        STOCK: stock,
                     };
    
        const   options =   {
                                method: 'POST',
                                headers: {
                                            'Content-Type': 'application/json'
                                         },
                                body: JSON.stringify(data)
                            };
            
        try {
            const response = await fetch(ConexionAPI.conexion+'/api/setUpdateStock',options);
            const data = await response.json();
        } catch (error) {
            console.error(error);
        }                    
        
    }

    /*ACTUALIZAR STOCK*/
    const updateStock = (id) => {
        if(id==editingId){
            setEditingId(null);
            let stock = document.getElementById('newStock').value;
            updateStockAPI(id,stock);
            window.location.reload()
            //ArrayData[id-1].stock = stock;
        }else{
            setEditingId(id);
        }
    };


    /*RENDERIZAR TABLA*/
    const renderTableData = () => {

        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow);

        let ArrayData = []
        for (var i = 0; i < currentRows.length; i++) {
            ArrayData.push(currentRows[i])
          }
        
        return ArrayData.map((row,index)=> (
            <tr key={index}>
                <td className="align-middle text-center">{row.codigo}</td>
                <td className="align-middle">{row.nombre}</td>
                <td className="align-middle">{row.linia}</td>
                <td className="align-middle text-center">{row.precio}</td>
                <td className="align-middle text-center">{row.precio_promocional}</td>
                <td>{editingId === row.id ? <input maxLength="2" onKeyPress={validateTypeNumber}  id='newStock' type="text" className='form-control' defaultValue={row.stock} /> : row.stock}</td>
                { 
                    rolUserDecrypt === "ADMIN" ? 
                    <td className="align-middle text-center">{editingId === row.id ? <input className='btn btn-warning' type='button' value='Guardar' onClick={ ()=>{updateStock(row.id)} }></input> : <button className='btn btn-outline-none' type='button' value='Editar' onClick={ ()=>{updateStock(row.id,row.stock)} }><img src={imgEdit}/></button>}</td>
                    : ''
                }
                <td className="align-middle text-center">
                    <button type="button" className="btn btn-outline-none" onClick={ () => {loadModal(row.nombre,row.codigo)}}>
                        <img className="cursorPointer" src={imgPhoto}  width="20"  />
                    </button>
                </td>
                <td className="align-middle text-center">
                    <img src={ row.stock === 0 ? notStock : (row.stock < 3 ? alertStock : inStock)  } width="30"/>
                </td>
            </tr>
        ))
    };

    return (
        <>
            <section className="mt-3">
                <h1>LISTAR PRODUCTOS</h1>
            </section>
            <div className="row row-sm">
                <div className="col-lg-6">
                    <div className='row form-check form-check-inline grid'>
                        <label className='col form-check-label'>
                            <input className="form-check-input"
                            type="radio"
                            value="1"
                            checked={selectedValue === '1'}
                            onChange={handleOptionChange}
                            />
                            BUSQUEDA POR CATEGORÍA
                        </label>
                        <label className='col form-check-label'>
                            <input className="form-check-input"
                            type="radio"
                            value="2"
                            checked={selectedValue === '2'}
                            onChange={handleOptionChange}
                            />
                            BUSQUEDA POR LINIA
                        </label>
                        <label className='col form-check-label'>
                            <input className="form-check-input"
                            type="radio"
                            value="3"
                            checked={selectedValue === '3'}
                            onChange={handleOptionChange}
                            />
                            BUSQUEDA POR CÓDIGO
                        </label>
                        
                    </div>
                </div>
                <div className="col-lg-6">
                    {renderTypeOfFilter()}
                </div>
            </div>
            { renderTableWithInformation && 
                (
                    <div>
                        <hr className="mt-3 mb-3"></hr>
                        <div>
                            <div className="table-responsive card mb-4">
                                <Table striped bordered hover className="m-0">
                                    <thead className="table-dark text-center">
                                        <tr>
                                            <th>CODIGO</th>
                                            <th>PRODUCTO</th>
                                            <th>CATEGORIA</th>
                                            <th>P.UNITARIO</th>
                                            <th>P.MAYOR</th>
                                            <th>STOCK</th>
                                            <th>EDITAR</th>
                                            {
                                                rolUserDecrypt === "ADMIN" ? <th>IMAGEN</th> :''
                                            }
                                            <th>ESTADO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { renderTableData() }
                                    </tbody>
                                </Table>
                            </div>
                            
                            <Pagination className='justify-content-center mt-2'>
                                <Pagination.Prev
                                    onClick={() => handlePaginationClick(currentPage - 1)}
                                    disabled={currentPage === 1}
                                />
                                {[...Array(Math.ceil(tableData.length / rowsPerPage)).keys()].map((pageNumber) => (
                                    <Pagination.Item
                                    key={pageNumber}
                                    active={pageNumber + 1 === currentPage}
                                    onClick={() => handlePaginationClick(pageNumber + 1)}
                                    >
                                    {pageNumber + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next
                                    onClick={() => handlePaginationClick(currentPage + 1)}
                                    disabled={currentPage === Math.ceil(tableData.length / rowsPerPage)}
                                />
                            </Pagination>
                        </div>
                    </div>
                )
                ||
                (
                    <div className="bodyInformation mt-3">
                        <b>Para empezar a realizar la busqueda es necesario indicar el filtro.</b>
                    </div>
                )
            }
        </>
    )
}

export const StockProductos = () => {
    return (
        <>
            <Navbar />
            <div className="container">
                { CargarFiltroSubcategorias() }
            </div>
            <div id="modal" className="modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 id="headerModal"></h5>
                            <span className="close">&times;</span>
                        </div>
                        <img className="m-3" id="bodyModal" />
                    </div>
                </div>
            </div>
        </>
    )
};